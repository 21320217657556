<template>
    <v-dialog v-model="isOpen" width="700px">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <ValidationObserver ref="observer" v-slot="{ invalid }">
                <v-toolbar dense flat>
                    <v-toolbar-title class="title primary--text">
                        Modification d'un contact
                    </v-toolbar-title>

                    <v-spacer />

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                                <v-icon> far fa-times-circle </v-icon>
                            </v-btn>
                        </template>
                        Fermer la fenêtre
                    </v-tooltip>
                </v-toolbar>

                <v-divider />

                <v-card-text class="pt-4 px-4">
                    <v-row class="mb-2">
                        <v-col cols="12" lg="4">
                            <ValidationProvider v-slot="{ errors, failed }" :name="getConfig('contacts.denonciation_mandatory_fields.title.name')" :rules="getConfig('contacts.denonciation_mandatory_fields.title.rules')">
                                <v-select v-model="contact.title" :items="titles" :label="getConfig('contacts.denonciation_mandatory_fields.title.name') + (getConfig('contacts.denonciation_mandatory_fields.title.required') ? ' *' : '')" clear-icon="far fa-times-circle" :menu-props="{ bottom: true, offsetY: true }" :hide-details="!failed" :error-messages="errors" outlined dense />
                            </ValidationProvider>
                        </v-col>

                        <v-col cols="12" lg="4">
                            <ValidationProvider v-slot="{ errors, failed }" :name="getConfig('contacts.denonciation_mandatory_fields.name.name')" :rules="getConfig('contacts.denonciation_mandatory_fields.name.rules')">
                                <v-text-field v-model="contact.name" :label="getConfig('contacts.denonciation_mandatory_fields.name.name') + (getConfig('contacts.denonciation_mandatory_fields.name.required') ? ' *' : '')" :hide-details="!failed" :error-messages="errors" outlined dense />
                            </ValidationProvider>
                        </v-col>

                        <v-col cols="12" lg="4">
                            <ValidationProvider v-slot="{ errors, failed }" :name="getConfig('contacts.denonciation_mandatory_fields.firstname.name')" :rules="getConfig('contacts.denonciation_mandatory_fields.firstname.rules')">
                                <v-text-field v-model="contact.firstname" :label="getConfig('contacts.denonciation_mandatory_fields.firstname.name') + (getConfig('contacts.denonciation_mandatory_fields.firstname.required') ? ' *' : '')" :hide-details="!failed" :error-messages="errors" outlined dense />
                            </ValidationProvider>
                        </v-col>
                    </v-row>

                    <ValidationProvider v-slot="{ errors, failed }" :name="getConfig('contacts.denonciation_mandatory_fields.email.name')" :rules="getConfig('contacts.denonciation_mandatory_fields.email.rules')">
                        <v-text-field v-model="contact.email" class="mb-4" :label="getConfig('contacts.denonciation_mandatory_fields.email.name') + (getConfig('contacts.denonciation_mandatory_fields.email.required') ? ' *' : '')" :hide-details="!failed" :error-messages="errors" outlined dense />
                    </ValidationProvider>

                    <v-row v-if="$store.state.application.providerCode === 'europeanhomes'" class="mb-2">
                        <v-col cols="12" lg="6">
                            <ValidationProvider v-slot="{ errors, failed }" vid="mobile" :name="getConfig('contacts.denonciation_mandatory_fields.mobile.name')" :rules="{ required: !contact.phone, 'phone': true }">
                                <v-text-field v-model="contact.mobile" :label="getConfig('contacts.denonciation_mandatory_fields.mobile.name') + (!contact.phone || contact.phone && contact.mobile ? ' *' : '')" :hide-details="!failed" :error-messages="errors" outlined dense />
                            </ValidationProvider>
                        </v-col>

                        <v-col cols="12" lg="6">
                            <ValidationProvider v-slot="{ errors, failed }" vid="phone" :name="getConfig('contacts.denonciation_mandatory_fields.phone.name')" :rules="{ required: !contact.mobile, 'phone': true }">
                                <v-text-field v-model="contact.phone" :label="getConfig('contacts.denonciation_mandatory_fields.phone.name') + (!contact.mobile || contact.phone && contact.mobile ? ' *' : '')" :hide-details="!failed" :error-messages="errors" outlined dense />
                            </ValidationProvider>
                        </v-col>
                    </v-row>

                    <v-row v-if="$store.state.application.providerCode !== 'europeanhomes'" class="mb-2">
                        <v-col cols="12" lg="6">
                            <ValidationProvider v-slot="{ errors, failed }" :name="getConfig('contacts.denonciation_mandatory_fields.mobile.name')" :rules="getConfig('contacts.denonciation_mandatory_fields.mobile.rules')">
                                <v-text-field v-model="contact.mobile" :label="getConfig('contacts.denonciation_mandatory_fields.mobile.name') + (getConfig('contacts.denonciation_mandatory_fields.mobile.required') ? ' *' : '')" :hide-details="!failed" :error-messages="errors" outlined dense />
                            </ValidationProvider>
                        </v-col>

                        <v-col cols="12" lg="6">
                            <ValidationProvider v-slot="{ errors, failed }" :name="getConfig('contacts.denonciation_mandatory_fields.phone.name')" :rules="getConfig('contacts.denonciation_mandatory_fields.phone.rules')">
                                <v-text-field v-model="contact.phone" :label="getConfig('contacts.denonciation_mandatory_fields.phone.name') + (getConfig('contacts.denonciation_mandatory_fields.phone.required') ? ' *' : '')" :hide-details="!failed" :error-messages="errors" outlined dense />
                            </ValidationProvider>
                        </v-col>
                    </v-row>

                    <ValidationProvider v-slot="{ errors, failed }" :name="getConfig('contacts.denonciation_mandatory_fields.address.name')" :rules="getConfig('contacts.denonciation_mandatory_fields.address.rules')">
                        <v-text-field v-model="contact.address" class="mb-4" :label="getConfig('contacts.denonciation_mandatory_fields.address.name') + (getConfig('contacts.denonciation_mandatory_fields.address.required') ? ' *' : '')" :hide-details="!failed" :error-messages="errors" outlined dense />
                    </ValidationProvider>

                    <v-row class="mb-2">
                        <v-col cols="12" lg="4">
                            <ValidationProvider v-slot="{ errors, failed }" :name="getConfig('contacts.denonciation_mandatory_fields.city.name')" :rules="getConfig('contacts.denonciation_mandatory_fields.city.rules')">
                                <v-text-field v-model="contact.city" :label="getConfig('contacts.denonciation_mandatory_fields.city.name') + (getConfig('contacts.denonciation_mandatory_fields.city.required') ? ' *' : '')" :hide-details="!failed" :error-messages="errors" outlined dense />
                            </ValidationProvider>
                        </v-col>

                        <v-col cols="12" lg="4">
                            <ValidationProvider v-slot="{ errors, failed }" :name="getConfig('contacts.denonciation_mandatory_fields.zip.name')" :rules="getConfig('contacts.denonciation_mandatory_fields.zip.rules')">
                                <v-text-field v-model="contact.zip" :label="getConfig('contacts.denonciation_mandatory_fields.zip.name') + (getConfig('contacts.denonciation_mandatory_fields.zip.required') ? ' *' : '')" :hide-details="!failed" :error-messages="errors" outlined dense />
                            </ValidationProvider>
                        </v-col>

                        <v-col cols="12" lg="4">
                            <ValidationProvider v-slot="{ errors, failed }" :name="getConfig('contacts.denonciation_mandatory_fields.country.name')" :rules="getConfig('contacts.denonciation_mandatory_fields.country.rules')">
                                <v-text-field v-model="contact.country" :label="getConfig('contacts.denonciation_mandatory_fields.country.name') + (getConfig('contacts.denonciation_mandatory_fields.country.required') ? ' *' : '')" :hide-details="!failed" :error-messages="errors" outlined dense />
                            </ValidationProvider>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-divider />

                <v-card-actions>
                    <v-btn :disabled="invalid" @click="submit()" color="primary" depressed small>
                        Modifier
                    </v-btn>

                    <v-spacer />

                    <v-btn @click="isOpen = false" depressed small>
                        Annuler
                    </v-btn>
                </v-card-actions>
            </ValidationObserver>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'UpdateContactDialog',

    props: {
        initContact: { type: Object, required: true }
    },

    data: () => ({
        isOpen: false,
        titles: [],
        contact: {}
    }),

    methods: {
        async submit() {
            const valid = await this.$refs.observer.validate();
            if (!valid) {
                return;
            }

            try {
                this.setLoading(true);

                const body = {
                    contact: this.contact
                };

                const { success, err } = await this.repos.contacts.updateContact(this.contact.contactPartnerId, body);

                if (success) {
                    this.$notify({
                        title: 'Information',
                        text: 'Le contact a bien été modifié',
                        type: 'success'
                    });
                    this.isOpen = false;
                    this.$emit('updated');
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la modification du contact',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    },

    watch: {
        isOpen(isOpen) {
            if (isOpen) {
                this.contact = JSON.parse(JSON.stringify(this.initContact));
            }
        }
    },

    created() {
        switch (this.$store.state.application.providerCode) {
            case 'lamotte':
                this.titles = ['Mme', 'Mr', 'Mr et Mme', 'MM', 'Mmes', 'SCI'];
                break;
            case 'letp':
                this.titles = ['Mme', 'Mr'];
                break;
            default:
                this.titles = ['Mlle', 'Mme', 'Mr', 'Mr et Mme', 'MM', 'Mmes', 'SCI'];
                break;
        }
    }
};
</script>
