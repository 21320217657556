<template>
    <v-container v-if="contact !== null">
        <v-row>
            <v-col cols="12" xl="5">
                <v-card class="axessia-card">
                    <v-toolbar class="toolbar-underline mb-2 mx-3" dense flat>
                        <v-toolbar-title class="primary--text title">
                            Identité
                        </v-toolbar-title>
                    </v-toolbar>

                    <v-card-text class="pt-2">
                        <v-sheet color="rounded grey lighten-4 pa-4">
                            <h2 class="primary--text mb-2">
                                {{ contact.title }} {{ contact.name }} {{ contact.firstname }}

                                <UpdateContactDialog :initContact="contact" @updated="fetchContact()">
                                    <template v-slot:activator="{ on }">
                                        <v-btn class="float-right" color="primary" outlined x-small v-on="on"> Modifier </v-btn>
                                    </template>
                                </UpdateContactDialog>
                            </h2>

                            <div class="d-flex">
                                <div>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-chip v-on="on" label small class="my-0 grey--text text--darken-2" color="transparent">
                                                <v-icon :color="contact.email ? 'primary' : 'grey'" x-small class="mr-1"> fas fa-envelope </v-icon>
                                                {{ contact.email }}
                                            </v-chip>
                                        </template>
                                        Adresse mail
                                    </v-tooltip>

                                    <br>

                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-chip v-on="on" label small class="my-0 grey--text text--darken-2" color="transparent">
                                                <v-icon :color="contact.mobile ? 'primary' : 'grey'" x-small class="mr-1"> fas fa-mobile-alt </v-icon>
                                                {{ contact.mobile }}
                                            </v-chip>
                                        </template>
                                        Portable
                                    </v-tooltip>

                                    <br>

                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-chip v-on="on" label small class="my-0 grey--text text--darken-2" color="transparent">
                                                <v-icon :color="contact.phone ? 'primary' : 'grey'" x-small class="mr-1"> fas fa-phone </v-icon>
                                                {{ contact.phone }}
                                            </v-chip>
                                        </template>
                                        Téléphone
                                    </v-tooltip>
                                </div>

                                <div class="ml-5">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-chip v-on="on" label small class="my-0 grey--text text--darken-2" color="transparent">
                                                <v-icon color="primary" left> fas fa-map-marker </v-icon>
                                                {{ contact.address }}
                                            </v-chip>
                                        </template>
                                        Adresse
                                    </v-tooltip> <br>

                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-chip v-on="on" label small class="my-0 grey--text text--darken-2 pr-1" color="transparent">
                                                <v-icon color="transparent" left> fas fa-map-marker </v-icon>
                                                {{ contact.zip }}
                                            </v-chip>
                                        </template>
                                        Code postal
                                    </v-tooltip>

                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-chip v-on="on" label small class="my-0 grey--text text--darken-2 px-1" color="transparent">
                                                {{ contact.city }}
                                            </v-chip>
                                        </template>
                                        Ville
                                    </v-tooltip> <br>

                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-chip v-on="on" label small class="my-0 grey--text text--darken-2 pr-1" color="transparent">
                                                <v-icon color="transparent" left> fas fa-map-marker </v-icon>
                                                {{ contact.country }}
                                            </v-chip>
                                        </template>
                                        Pays
                                    </v-tooltip>
                                </div>
                            </div>

                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-chip v-on="on" label small class="my-0 grey--text text--darken-2" color="transparent">
                                        <v-icon color="primary" small class="mr-1"> fas fa-calendar </v-icon>
                                        {{ contact.created | toDatetime(true) }}
                                    </v-chip>
                                </template>
                                Premier contact
                            </v-tooltip>

                            <v-tooltip top v-if="contact.modified && contact.modified !== contact.created">
                                <template v-slot:activator="{ on }">
                                    <v-chip v-on="on" label small class="my-0 grey--text text--darken-2" color="transparent">
                                        <v-icon color="primary" small class="mr-1"> fas fa-calendar </v-icon>
                                        {{ contact.modified | toDatetime(true) }}
                                    </v-chip>
                                </template>
                                Dernier contact
                            </v-tooltip>

                            <br>
                            <br>

                            <div class="d-flex justify-end">
                                <CreateDenonciationDialog :visitorId="contact.id" :initPartnerContactId="contact.contactPartnerId" @denonciation-created="fetchContact()">
                                    <template v-slot:activator="{ on: dialog }">
                                        <v-btn color="primary" class="mr-2" small v-on="{ ...dialog }">
                                            <v-icon small left> far fa-address-card </v-icon>
                                            Dénoncer à nouveau
                                        </v-btn>
                                    </template>
                                </CreateDenonciationDialog>

                                <CreateOptionDialog v-if="canCreateoption" :initVisitorId="contact.id" isOption>
                                    <template v-slot:activator="{ on: dialog }">
                                        <v-btn color="primary" class="mr-2" small v-on="{ ...dialog }">
                                            <v-icon small left>
                                                fas fa-clock
                                            </v-icon>
                                            Poser une option
                                        </v-btn>
                                    </template>
                                </CreateOptionDialog>

                                <CreateOptionDialog v-if="getConfig('sales.enabled', false) && !getConfig('sales.sale_must_be_created_from_option', true)" :initVisitorId="contact.id" :isOption="false">
                                    <template v-slot:activator="{ on: dialog }">
                                        <v-btn color="primary" small v-on="{ ...dialog }">
                                            <v-icon small left>
                                                fas fa-shopping-cart
                                            </v-icon>
                                            Enregistrer une réservation
                                        </v-btn>
                                    </template>
                                </CreateOptionDialog>
                            </div>
                        </v-sheet>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" xl="7">
                <v-card class="axessia-card">
                    <v-toolbar class="toolbar-underline mb-2 mx-3" dense flat>
                        <v-toolbar-title class="primary--text title">
                            Activités
                        </v-toolbar-title>
                    </v-toolbar>

                    <v-card-text class="pt-2">
                        <v-sheet color="rounded grey lighten-4 pa-4">
                            <v-row>
                                <v-col>
                                    <v-chip-group v-model="typesFilter" active-class="primary lighten-1" multiple>
                                        <v-chip class="mr-2" label value="denonciation">
                                            <v-chip color="white" class="px-2" small label> {{ partnerActivitiesDenonciationCount }} </v-chip>
                                            {{ partnerActivitiesDenonciationCount | plural('dénonciation', 'dénonciations') }}
                                        </v-chip>

                                        <v-chip class="mr-2" label value="option">
                                            <v-chip color="white" class="px-2" small label> {{ partnerActivitiesOptionCount }} </v-chip>
                                            {{ partnerActivitiesOptionCount | plural('option', 'options') }}
                                        </v-chip>

                                        <v-chip class="mr-2" label value="sale">
                                            <v-chip color="white" class="px-2" small label> {{ partnerActivitiesSaleCount }} </v-chip>
                                            {{ partnerActivitiesSaleCount | plural('réservation', 'réservations') }}
                                        </v-chip>
                                    </v-chip-group>
                                </v-col>

                                <v-col class="text-right">
                                    <v-pagination v-model="page" @input="fetchPartnerActivities()" :length="partnerActivitiesTotalPages" :total-visible="7" class="pagination" color="primary" />
                                </v-col>
                            </v-row>

                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="subtitle-2 primary--text"> Type </th>
                                            <th class="subtitle-2 primary--text"> Date </th>
                                            <th class="subtitle-2 primary--text"> Programme </th>
                                            <th class="subtitle-2 primary--text"> Lot </th>
                                            <th class="subtitle-2 primary--text"> Actions </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="(activity, i) of partnerActivities" :key="i">
                                            <td>
                                                <template v-if="activity.type === 'sale'">
                                                    <v-chip :color="getSaleStatus(activity.status, 'color')" :to="getConfig('sales.enabled', false) ? `/reservations/${activity.activityId}` : ''" label small dark>
                                                        Réservation
                                                        -
                                                        {{ getSaleStatus(activity.status, 'label') }}
                                                    </v-chip>
                                                </template>

                                                <template v-if="activity.type === 'option'">
                                                    <v-chip :color="getOptionStatus(activity.statusdetail, 'color')" label small dark>
                                                        Option
                                                        -
                                                        {{ getOptionStatus(activity.statusdetail, 'statusLabel') }}
                                                    </v-chip>
                                                </template>

                                                <template v-if="activity.type === 'denonciation'">
                                                    <v-chip :color="getDenonciationStatus(activity.statusdetail, 'color')" label small dark>
                                                        Dénonciation
                                                        -
                                                        {{ getDenonciationStatus(activity.statusdetail, 'statusLabel') }}
                                                    </v-chip>
                                                </template>
                                            </td>

                                            <td>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <span v-on="on"> {{ activity.statusdate | toDate() }} </span>
                                                    </template>
                                                    {{ activity.statusdatelabel }}
                                                </v-tooltip>
                                            </td>

                                            <td>
                                                <template v-if="activity.program">
                                                    <router-link :to="`/programmes/${activity.program.id}`" class="grey--text text--darken-2">
                                                        {{ activity.program.title }}
                                                        ({{ activity.program.city }})
                                                    </router-link>
                                                </template>
                                            </td>

                                            <td>
                                                <template v-if="activity.product">
                                                    {{ activity.product.reference }} - {{ activity.price | toCurrencyString() }}
                                                </template>
                                            </td>

                                            <td>
                                                <template v-if="activity.type === 'sale'">

                                                </template>

                                                <template v-if="activity.type === 'option'">
                                                    <OptionToSaleDialog v-if="canConvertOptionToSale(activity.statusdetail)" @reload="fetchPartnerActivities()" :optionId="activity.activityId">
                                                        <template v-slot:activator="{ on: menu }">
                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on: tooltip }">
                                                                    <v-btn color="primary" class="mb-1 mr-1" depressed x-small dark v-on="{...menu, ...tooltip}">
                                                                        <v-icon x-small> fas fa-shopping-cart </v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                Transformer l'option en réservation
                                                            </v-tooltip>
                                                        </template>
                                                    </OptionToSaleDialog>

                                                    <ConfirmOptionDialog v-if="canConfirmOption(activity.statusdetail)" @reload="fetchPartnerActivities()" :optionId="activity.activityId" :expirationDate="activity.expirationdate">
                                                        <template v-slot:activator="{ on: menu }">
                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on: tooltip }">
                                                                    <v-btn color="primary" depressed x-small class="mb-1 mr-1" dark v-on="{ ...menu, ...tooltip }">
                                                                        <v-icon x-small> fas fa-check-square </v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                Confirmer l'option
                                                            </v-tooltip>
                                                        </template>
                                                    </ConfirmOptionDialog>

                                                    <CancelOptionDialog v-if="canCancelOption(activity.statusdetail)" @reload="fetchPartnerActivities()" :optionId="activity.activityId">
                                                        <template v-slot:activator="{ on: menu }">
                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on: tooltip }">
                                                                    <v-btn color="primary" depressed x-small class="mb-1 mr-1" dark v-on="{...menu, ...tooltip}">
                                                                        <v-icon x-small> fas fa-times-circle </v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                Annuler l'option
                                                            </v-tooltip>
                                                        </template>
                                                    </CancelOptionDialog>
                                                </template>

                                                <template v-if="activity.type === 'denonciation'">

                                                </template>
                                            </td>
                                        </tr>

                                        <tr v-if="partnerActivities.length === 0">
                                            <td colspan="5" class="text-center font-italic"> Aucune activité </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-sheet>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import salesMixin from '../mixins/sales.js';
import optionsMixin from '../mixins/options.js';
import denonciationsMixin from '../mixins/denonciations.js';

import CancelOptionDialog from '../components/options/CancelOptionDialog.vue';
import CreateOptionDialog from '../components/dialogs/CreateOptionDialog.vue';
import OptionToSaleDialog from '../components/options/OptionToSaleDialog.vue';
import ConfirmOptionDialog from '../components/options/ConfirmOptionDialog.vue';
import UpdateContactDialog from '../components/contact/UpdateContactDialog.vue';
import CreateDenonciationDialog from '../components/contacts/CreateDenonciationDialog.vue';

export default {
    name: 'Contact',

    mixins: [salesMixin, optionsMixin, denonciationsMixin],

    components: {
        CancelOptionDialog,
        CreateOptionDialog,
        OptionToSaleDialog,
        ConfirmOptionDialog,
        UpdateContactDialog,
        CreateDenonciationDialog
    },

    data: () => ({
        page: 1,
        typesFilter: ['denonciation', 'option', 'sale'],

        contact: null,
        firstPartnerActivitiesRequest: true,
        partnerActivities: [],
        partnerActivitiesCount: 0,
        partnerActivitiesSaleCount: 0,
        partnerActivitiesOptionCount: 0,
        partnerActivitiesDenonciationCount: 0,
        partnerActivitiesTotalPages: 0
    }),

    computed: {
        canCreateoption() {
            return this.contact.activities.denonciation && this.contact.activities.denonciation.statusdetail === 'validated';
        }
    },

    methods: {
        async fetchContact() {
            try {
                this.setLoading(true);

                const query = {
                    include: 'activities'
                };
                const { contact } = await this.repos.contacts.getContact(this.$route.params.contactId, query);
                this.contact = contact;
                this.fetchPartnerActivities();
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du rechargement de la page',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        async fetchPartnerActivities() {
            try {
                this.setLoading(true);

                const query = {
                    page: this.page,
                    limit: 13,
                    visitorIds: [this.contact.id],
                    types: this.typesFilter.join(',')
                };

                const { partnerActivities, count, optionCount, saleCount, denonciationCount, totalPages } = await this.repos.partnerActivities.getActivities(query);
                this.partnerActivities = partnerActivities;
                this.partnerActivitiesCount = count;
                this.partnerActivitiesTotalPages = totalPages;

                if (this.firstPartnerActivitiesRequest) {
                    this.partnerActivitiesOptionCount = optionCount;
                    this.partnerActivitiesSaleCount = saleCount;
                    this.partnerActivitiesDenonciationCount = denonciationCount;
                }

                this.firstPartnerActivitiesRequest = false;
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du rechargement de la page',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    },

    watch: {
        typesFilter() {
            this.fetchPartnerActivities();
        }
    },

    created() {
        this.fetchContact();
    }
};
</script>

<style lang="scss" scoped>
.pagination::v-deep .v-pagination {
    width: initial;
    max-width: initial;

    .v-pagination__navigation:last-child {
        margin-right: 0;
    }
}
</style>